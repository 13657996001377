import React from 'react';
import Sanctuary from '../../images/sanctuary.png';

import './Hometron.css';

const Hometron = () => {
	return (
		<div className='container-fluid px-0'>
			<div className='row'>
				<div className='col-md-12'>
					<img
						src={Sanctuary}
						width='1980'
						height='600'
						alt='placeholder 960'
						className='img-responsive'
					/>
					<div className='centered display-2'>
						Cave City Christian Church
						<h4 className='home-schedule'>
							Sunday School 9:30 a.m. | Sunday Service 10:30 a.m.
							| Wednesday Bible Study 6:30 p.m.
						</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hometron;
