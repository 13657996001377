import React from 'react';
import Container from 'react-bootstrap/Container';

import './Story.css';

const Beliefs = () => {
	return (
		<div>
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>Our Beliefs</h1>
					<p className='lead text-left'>
						People often struggle with which church to attend. After
						all, there are so many different denominations. And is
						there really that much difference in them?{' '}
					</p>
					<p className='lead text-left'>
						The Bible tells us that when Jesus established His
						Church, He meant for there to be only One. In Matthew
						16:18, Jesus says, “Upon this rock I will build My
						church.” In John 17:11, when Jesus prayed for His
						church, He prayed to the Father, “that they may be one
						as we are one.” He prayed for the complete unity of His
						church (John 17:23) so that the world would believe that
						God had sent Him. The divided, fragmented picture of the
						Church that most people see today is not what we were
						meant to be. In Ephesians 4, we read that there is “one
						body, one Spirit,…one hope,…one Lord, one faith, one
						baptism, one God and Father over all.”{' '}
					</p>
					<p className='lead text-left'>
						The Christian Church calls for a return to the unity and
						oneness that we read about in the New Testament. We
						believe that if we allow Jesus Christ to be the Head of
						the Church, rather than conventions, synods, or
						assemblies; if we accept the Word of God to be inspired
						and the sole authority for the teaching and doctrines of
						the Church; and if we practice the love toward one
						another that is the true mark of being a Disciple of
						Jesus, then we can be the kind of church that Jesus
						established and prayed for.{' '}
					</p>
					<p className='lead text-left'>
						Based on that, we only wear the name “Christian” to mark
						us as belonging to Jesus Christ and to promote unity
						with other believers. We observe the New Testament
						ordinances of baptism by immersion and the Lord’s
						Supper, both of which were commanded by our Lord. We
						accept Christ alone as the Head of the Church and the
						Bible alone as our rule of faith and practice. We
						fellowship with all who embrace Christ and His Word, and
						join with believers around the world in spreading the
						Gospel of Christ.
					</p>
					<p className='lead text-left'>
						We do not claim to be the only Christians out there, but
						we are Christians only and intent on being true to the
						Word of God and to the Lord who died for us to make our
						salvation possible. If this plea for Biblical unity and
						faithfulness to the Lord appeals to you, perhaps the
						Christian Church is just what you’ve been looking for.
						We hope you will feel welcome here!
					</p>
					<p className='lead text-left'>
						In keeping with the principles of the Restoration
						Movement, we do not, strictly speaking, have a creed or
						official statement of beliefs. Instead we hold to the
						Scriptures as our only official rule of faith and
						practice. However, we also recognize that this can be
						challenging. Different people hold different
						interpretations of Scripture, or different ideas of what
						should or should not be included in Scripture, and so
						there is a need to clarify what we mean when we say the
						Bible is our only source of authority. Below you will
						find some specific examples of what we believe and where
						those beliefs come from.
					</p>
					<p className='lead text-left'>
						We believe in God the Father Almighty, maker of heaven
						and earth, of all things visible and invisible. [Genesis
						1-2; Isaiah 45:18; Psalm 104; Jeremiah 10:10-12; Acts
						17:22-31]
					</p>
					<p className='lead text-left'>
						We believe in Jesus Christ the Son our Lord, fully God
						and fully man, who for us and for our salvation took on
						flesh and was born of the virgin Mary by the Holy
						Spirit. We believe that Jesus died for us according to
						the scriptures, that he was buried, that on the third
						day he rose again according to the scriptures, that he
						ascended into heaven to sit at the right hand of the
						Father, and that he will return in glory to judge the
						living and the dead. [Isaiah 9:1-7; Isaiah 53; John
						1:1-18; Matthew 1; Romans 1:2-4; Philippians 2:5-11;
						Colossians 1:15-20; Hebrews 1:1-4; 1 Corinthians
						15:1-11]
					</p>
					<p className='lead text-left'>
						We believe in the Holy Spirit, the Lord and Giver of
						life, who together with the Father and Son is worshipped
						and glorified, who spoke by the prophets and inspired
						the scriptures, who indwells and empowers the church,
						and who convicts the world. [Genesis 1:1-2; Joel
						2:28-32; Acts 2; John 14-16; Romans 8; 1 Corinthians
						12-14; 2 Corinthians 13:14; Galatians 5:16-26]
					</p>
					<p className='lead text-left'>
						We believe in the Holy Scriptures as the inspired,
						trustworthy, and authoritative written word of God.
						[John 5:37-40; Romans 1:1-2; 2 Timothy 3:14-17; 2 Peter
						1:16-21].
					</p>
					<p className='lead text-left'>
						We believe in one, holy, universal Church, built on the
						foundation of the Apostles and prophets, the body of
						Christ, the bride of Christ, the temple of the Holy
						Spirit, and the new covenant people of God. [Matthew
						16:13-20; Luke 22:14-23; Acts 2; 1 Corinthians 12;
						Ephesians 2; 1 Peter 2:1-12; Revelation 7:9-12;
						Revelation 19:6-10]
					</p>
					<p className='lead text-left'>
						We believe that the mission of the church flows from the
						mission of God to redeem and restore his creation: we
						are called to make disciples of all nations, baptizing
						in the name of the Father, Son, and Holy Spirit, and
						teaching disciples to obey Jesus in all things. [Genesis
						12:1-3; Matthew 28:16-20; Mark 16:14-20; Luke 24:36-49;
						John 20:19-23; Acts 1:1-8; 2 Corinthians 5:11-21]
					</p>
					<p className='lead text-left'>
						We believe in the forgiveness of sins and salvation by
						God's grace. We believe that a person enters into
						salvation and the life of Christian discipleship through
						faith, repentance, baptism by immersion, and confession
						of the name of Christ. [Mark 1:14-15; Matthew 28:16-20;
						John 3:16-21; John 14:1-11; Acts 2-3; Romans 3:21-25;
						Romans 6:1-4; Romans 10:5-13; 1 Peter 3:18-22]
					</p>
					<p className='lead text-left'>
						We believe that humans were created in the image of God
						and that therefore all human life has great sanctity and
						is worthy of utmost respect. We believe that all people
						are sinners separated from God who stand in need of
						God's redeeming grace. [Genesis 1:26-28; Exodus 20:13;
						Psalm 8; John 3:16; Genesis 3; Romans 1:18-3:20]
					</p>
					<p className='lead text-left'>
						We believe in the resurrection of the dead at the return
						of Jesus and in the fullness of God's kingdom in the
						coming new heavens and earth. [Isaiah 65:17-25; Isaiah
						66:15-25; Matthew 25; John 5:25-29; 1 Corinthians 15;
						Revelation 20-22]
					</p>
				</div>
			</Container>
		</div>
	);
};

export default Beliefs;
