import React, { useState } from 'react';

import emailjs from 'emailjs-com';

import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Contacttron from '../../components/Jumbotron/Contacttron';
import { Container, Dropdown, Button, Row, Col, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import './ContactUs.css';

const ContactUs = () => {
	const [buttonText, setButtonText] = useState('Submit');
	const [verified, setVerified] = useState(false);
	const [email, setEmail] = useState({
		from_name: '',
		to_name: 'Whom it may concern',
		message: '',
		reply_to: '',
		interest_in: 'No interest submitted',
	});

	const clearForm = () => {
		setEmail({
			from_name: '',
			to_name: 'Whom it may concern',
			message: '',
			reply_to: '',
			interest_in: 'No interest submitted',
		});
	};

	const sendEmail = (e) => {
		e.preventDefault();
		setButtonText('Sending...');
		try {
			emailjs
				.send(
					'service_ujd9skb',
					'temp_website',
					{
						to_name: email.to_name,
						from_name: email.from_name,
						interest_in: email.interest_in,
						message: email.message,
						reply_to: 'info@cavecitychristian.org',
					},
					'Redemption1!'
				)
				.then(
					(result) => {
						while (result.status === undefined) {}
						setButtonText('Submit');
						clearForm();
						//leave this page and go to the home page i assume
					},
					(error) => {
						console.log(error.text);
					}
				);
		} catch (error) {
			console.log(error);
		}
	};

	const handleVerify = (token) => {
		if (token.length > 50) {
			setVerified(true);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setEmail({ ...email, [name]: value });
	};

	const handleSelect = (value) => {
		setEmail({ ...email, interest_in: value });
	};

	return (
		<div>
			<Contacttron />
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>
						We want to hear from you! Contact us
					</h1>
					<p className='lead'>
						Please fill out this form and we will get in touch with
						you shortly.
					</p>
				</div>
			</Container>
			<div className='contact-container'>
				<Form className='contact-form'>
					<Row>
						<Col>
							<Form.Group controlId='formBasicEmail'>
								<Form.Control
									name='from_name'
									type='text'
									placeholder='Enter Name'
									onChange={handleChange}
								/>
								<Form.Text className='text-muted'>
									We are so happy to get to know you.
								</Form.Text>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId='formBasicEmail'>
								<Form.Control
									name='reply_to'
									type='email'
									placeholder='Enter your email'
									onChange={handleChange}
								/>
								<Form.Text className='text-muted'>
									We'll never share your email with anyone
									else.
								</Form.Text>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Dropdown
								title='interest_in'
								onSelect={handleSelect}>
								<Dropdown.Toggle
									variant='secondary'
									id='dropdown-basic'></Dropdown.Toggle>
								<Form.Text className='text-muted tell-us'>
									{email.interest_in ===
									'No interest submitted'
										? 'Tell us what you are interested in.'
										: email.interest_in}
								</Form.Text>
								<Dropdown.Menu>
									<Dropdown.Item eventKey='Looking for a church'>
										Looking for a church
									</Dropdown.Item>
									<Dropdown.Item eventKey='I need prayers'>
										I need prayers
									</Dropdown.Item>
									<Dropdown.Item eventKey='Want to get involved'>
										Want to get involved
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className='mt-4'>
								<Form.Control
									name='message'
									as='textarea'
									rows={3}
									placeholder='Message'
									onChange={handleChange}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								onClick={sendEmail}
								variant='outline-secondary'
								className='button-b mt-4'>
								{buttonText === 'Sending...' && (
									<Spinner
										as='span'
										animation='border'
										size='sm'
										role='status'
										aria-hidden='true'
									/>
								)}

								{buttonText}
							</Button>
						</Col>
						<Col>
							<GoogleReCaptcha onVerify={handleVerify} />
						</Col>
					</Row>
				</Form>
			</div>
			<Container className='contact-info'>
				<Row>
					<Col>
						<h3>Address:</h3>
						300 Broadway St.
						<br /> P.O. Box 487
						<br /> Cave City, KY 42127
					</Col>
					<Col>
						<h3>Phone:</h3> 270-773-3643 <br />
					</Col>
					<Col>
						<h3>Email:</h3>info@cavecitychristian.org
					</Col>
				</Row>
			</Container>
		</div>
	);
};
// client site key = 6LdFg4YaAAAAAN0j1ngwiWomQwXWv_z6lbaPxGJv
// server side integration = 6LdFg4YaAAAAAHz4gAFtoivDled1r4NaRCHhT-Mw
export default ContactUs;
