import React from 'react';
import Container from 'react-bootstrap/Container';

import './Story.css';

const Adults = () => {
	return (
		<div>
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>For Adults</h1>
					<br />
					<h6 className='display-6'>Sunday School</h6>
					<p className='lead text-left'>
						We currently offer two adult Sunday School classes. One
						meets in the fellowship hall and studies from the
						Standard Lesson Curriculum. The other meets in the
						library and studies a variety of pertinent,
						discussion-oriented, topics. You are welcome to join
						either class on Sundays at 9:30am.
					</p>
					<h6 className='display-6'>Grief Share</h6>
					<p className='lead text-left'>
						We are a church partner with GriefShare, a ministry
						designed to help those who are struggling with the loss
						of a loved one. Visit griefshare.org or contact the
						church directly for more information about this program
						and when it is meeting.
					</p>
					<h6 className='display-6'>Midweek Bible Study</h6>
					<p className='lead text-left'>
						Our adult Bible Study meets on Wednesdays at 6:30pm in
						the sanctuary. This relaxed, discussion-oriented service
						consists of a time of prayer followed by study of God’s
						Word. Our Bible Study time is designed to help us dive
						deeper into God's Word and learn in greater detail what
						it has to say. Everyone is invited to join us.
					</p>
					<h6 className='display-6'>Men’s Ministry</h6>
					<p className='lead text-left'>
						The men’s ministry focuses on offering the men of our
						congregation unique opportunities to get together for
						in-depth study and fellowship with one another. More
						information is available from the church directly.{' '}
					</p>
					<h6 className='display-6'>Women’s Ministry</h6>
					<p className='lead text-left'>
						The women’s ministry focuses on offering the women of
						our congregation unique opportunities to get together
						for in-depth study and fellowship with one another. More
						information is available from the church directly.
					</p>
					<h6 className='display-6'>Special Events</h6>
					<p className='lead text-left'>
						CCCC hosts a variety of special events throughout the
						year, many of which focus on encouraging and deepening
						our faith as adults. Check out our events page for
						information about upcoming opportunities!
					</p>
				</div>
			</Container>
		</div>
	);
};

export default Adults;
