import React from "react";
import Givetron from "../../components/Jumbotron/Givetron";
import Tithely from "./sections/Tithely";
import Mail from "./sections/Mail";

const Give = () => {
  return (
    <div>
      <Givetron />
      <Mail />
      <Tithely />
    </div>
  );
};

export default Give;
