import React from 'react';
import OurStorytron from '../../components/Jumbotron/OurStorytron';
import Beliefs from './sections/Beliefs';

const OurBeliefs = () => {
	return (
		<div>
			<OurStorytron title={'Beliefs'} />
			<Beliefs />
		</div>
	);
};

export default OurBeliefs;
