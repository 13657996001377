import Navbar from './components/Navbar/Navbar';
import {
	BrowserRouter as Router,
	Routes as Switch,
	Route,
} from 'react-router-dom';
import Home from './pages/Home/Home';
import Give from './pages/Give/Give';
import Pastor from './pages/Pastor/Pastor';
import ContactUs from './pages/ContactUs/ContactUs';
import OurStory from './pages/OurStory/OurStory';
import OurBeliefs from './pages/OurStory/OurBeliefs';
import OurMinistries from './pages/OurStory/OurMinistries';
import ForStudents from './pages/OurStory/ForStudents';
import ForAdults from './pages/OurStory/ForAdults';
import Footer from './components/Footer/Footer';
import Sermon from './pages/Sermon/Sermon';
import Events from './pages/Events/Events';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
	return (
		<div className='App'>
			<GoogleReCaptchaProvider reCaptchaKey='6LdFg4YaAAAAAN0j1ngwiWomQwXWv_z6lbaPxGJv'>
				<Router>
					<Navbar />
					<Switch>
						<Route exact path='/' element={<Home />} />
						<Route exact path='/pastor' element={<Pastor />} />
						<Route
							exact
							path='/contactus'
							element={<ContactUs />}
						/>
						<Route exact path='/give' element={<Give />} />
						<Route exact path='/ourstory' element={<OurStory />} />
						<Route exact path='/beliefs' element={<OurBeliefs />} />
						<Route
							exact
							path='/ministries'
							element={<OurMinistries />}
						/>
						<Route exact path='/adults' element={<ForAdults />} />
						<Route
							exact
							path='/students'
							element={<ForStudents />}
						/>
						<Route exact path='/sermons' element={<Sermon />} />
						<Route exact path='/events' element={<Events />} />
					</Switch>
				</Router>
			</GoogleReCaptchaProvider>
			<Footer />
		</div>
	);
}

export default App;
