import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import pastorJpg from '../../../images/pastor.jpg';

const LifeStory = () => {
	return (
		<div>
			<Container className='mt-5'>
				<Row>
					<Col>
						<Image
							style={{ height: '500px' }}
							src={pastorJpg}
							rounded
						/>
					</Col>
					<Col>
						<h1>Keith Robinson</h1>
						<h3>Pastor</h3>
						<span>
							<FontAwesomeIcon icon={faEnvelope} />
							&nbsp;&nbsp; cavecitychristian@gmail.com
							<br />
							<FontAwesomeIcon icon={faPhoneAlt} />
							&nbsp;&nbsp; 270-773-3643
						</span>
						<br />
						<br />
						<h3>Life Story</h3>
						<p>
							Keith became the minister of Cave City Christian
							Church in 2020 after serving at a church in Ohio for
							5.5 years. He has earned a Bachelor's degree  in
							Preaching from Central Christian College of the
							Bible in Moberly, MO and a Master's in Bible and
							Theology from Louisville Bible College in
							Louisville, KY.
						</p>
						<p>
							Originally from Michiana (Michigan-Indiana), Keith
							is an avid musician who sings on the church praise
							team, conducts the church choir, and plays the
							violin for special events and with the Louisville
							Philharmonia. In addition to his ministry with CCCC,
							Keith also serves on the board of the White Mills
							Christian Camp in White Mills, KY, and regularly
							volunteers at Pathfinders, a local food pantry in
							Cave City, KY. He is also an adjunct professor at
							Louisville Bible College.
						</p>
						<p>
							"Preaching and ministering in Cave City is something
							I consider a great blessing. I encourage you to come
							join us at Cave City Christian Church and be a part
							of what God is doing in our community!" - Keith
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default LifeStory;
