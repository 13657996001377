import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from '../../images/cccc_logo.png';
import './Navbar.css';

const Navigation = () => {
	return (
		<div className='parent-navbar'>
			<Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
				<Navbar.Brand>
					<img
						src={Logo}
						alt='Logo'
						width='50'
						height='20'
						color='#666'
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='mr-auto'>
						<LinkContainer to='/'>
							<Nav.Link>HOME</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/sermons'>
							<Nav.Link>SERVICES</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/pastor'>
							<Nav.Link>PASTOR</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/contactus'>
							<Nav.Link>CONTACT US</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/give'>
							<Nav.Link>GIVE</Nav.Link>
						</LinkContainer>

						<NavDropdown title='OUR STORY' id='basic-nav-dropdown'>
							<LinkContainer to='/ourstory'>
								<NavDropdown.Item>About Us</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to='/beliefs'>
								<NavDropdown.Item>Our Beliefs</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to='/ministries'>
								<NavDropdown.Item>
									Our Ministries
								</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to='/adults'>
								<NavDropdown.Item>For Adults</NavDropdown.Item>
							</LinkContainer>
							<LinkContainer to='/students'>
								<NavDropdown.Item>
									For Students
								</NavDropdown.Item>
							</LinkContainer>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default Navigation;
