import React from 'react';

const RecurringLive = () => {
	return (
		<div
			className='embed-responsive embed-responsive-16by9'
			style={{ width: 440, height: 'auto' }}>
			<div style={{ padding: '75% 0 0 0', position: 'relative' }}>
				<iframe
					title='sermon showcases'
					src='https://vimeo.com/event/2496434/embed'
					allow='autoplay; fullscreen; picture-in-picture'
					allowFullScreen
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}></iframe>
			</div>
		</div>
	);
};

export default RecurringLive;
