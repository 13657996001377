import axios from 'axios';

var showCaseOptions = {
	method: 'GET',
	url: 'https://api.vimeo.com/users/132644003/albums',
	headers: { Authorization: 'bearer 1c135b8bf7938bcc10d426044722c357' },
};

var showRecurLive = {
	method: 'GET',
	url: 'https://vimeo.com/event/2255265',
	headers: { Authorization: 'bearer 1c135b8bf7938bcc10d426044722c357' },
};

export const fetchData = async (type) => {
	const data = await axios
		.request(determineType(type))
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (error) {
			console.error(error);
		});

	return data;
};

const determineType = (type) => {
	switch (type) {
		case 'ShowCase':
			return showCaseOptions;
		case 'RecurringLive':
			return showRecurLive;
		default:
			break;
	}
};
