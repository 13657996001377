import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Welcome.css';

const Welcome = () => {
	return (
		<div>
			<Container>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>
						Welcome to Cave City Christian Church
					</h1>
					<p className='lead'>
						We are glad you are here! Feel free to look around, or
						contact us if you have any questions. Be sure to check
						our Facebook page for all of the latest updates on
						what’s happening at CCCC!
					</p>
				</div>
			</Container>
			<div className='container'>
				<div className='row welcome-item'>
					<div className='col-lg-4'>
						<img
							src='https://wporganic.com/theme/praise-church/wp-content/uploads/2018/04/icon-1.png'
							className='welcome-img'
							alt=''
						/>
						<h2>Our Mission</h2>
						<p>
							To be a Church where Christ is Preached, and You are
							Loved!
						</p>
					</div>
					<div className='col-lg-4'>
						<img
							src='https://wporganic.com/theme/praise-church/wp-content/uploads/2018/04/icon-2.png'
							className=''
							alt=''
						/>
						<h2>Our Location</h2>
						<p>We are located at 300 Broadway in Cave City, KY.</p>
					</div>
					<div className='col-lg-4'>
						<img
							src='https://wporganic.com/theme/praise-church/wp-content/uploads/2018/04/icon-3.png'
							className=''
							alt=''
						/>
						<h2>Our Beliefs</h2>
						<p>
							We are an independent, Bible-centered church
							affiliated with the Restoration Movement.
						</p>
					</div>
				</div>
			</div>

			<Container className='mt-3'>
				<Row className='welcome-buttons'>
					<Col sm={2}></Col>
					<Col lg={3} xs={10}>
						<LinkContainer to='/pastor'>
							<Nav.Link>
								<Button
									variant='outline-secondary'
									className='button-b'>
									MEET OUR PASTOR
								</Button>
							</Nav.Link>
						</LinkContainer>
					</Col>
					<Col sm={2}></Col>
					<Col lg={3} xs={10}>
						<LinkContainer to='/ourstory'>
							<Nav.Link>
								<Button
									variant='outline-secondary'
									className='button-b'>
									WHO WE ARE
								</Button>
							</Nav.Link>
						</LinkContainer>
					</Col>
					<Col sm={2}></Col>
				</Row>
			</Container>
		</div>
	);
};

export default Welcome;
