import React from 'react';
import Container from 'react-bootstrap/Container';

import './Story.css';

const Ministries = () => {
	return (
		<div>
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>Our Ministries</h1>
					<br />
					<h6 className='display-6'>Sunday Morning Services</h6>
					<p className='lead text-left'>
						Every Sunday, we gather together as a body of believers
						to worship our Creator at 10:30am. Our time of corporate
						worship includes singing praises to God, prayer,
						communion, offerings, preaching, and opportunities of
						fellowship, all geared towards honoring God. We
						encourage you to participate as you feel comfortable and
						led.
					</p>
					<h6 className='display-6'>Communion</h6>
					<p className='lead text-left'>
						The Lord’s Supper was inaugurated during Jesus’ final
						Passover meal with His disciples shortly before His
						death; and commemorated by the early Church on the first
						day of each week, a practice we follow. Communion is
						served at every Sunday service for those who have
						gathered to be able to partake as an act of worship in
						remembrance of Jesus’ sacrifice and to honor Him for His
						salvation freely given to us. Visitors and guests are
						welcome but not obligated to participate.
					</p>
					<h6 className='display-6'>Offering</h6>
					<p className='lead text-left'>
						We give because He first gave freely to us. As the
						offering plates are passed, do not feel obligated to
						give; rather, decide if you would like to give to the
						work CCCC is doing.
					</p>
					<h6 className='display-6'>Sermon</h6>
					<p className='lead text-left'>
						Our sermon time exists for the purpose of equipping the
						Church to serve the world. Hearing the word of God
						taught each week exhorts, encourages, and propels us as
						the Church to carry out our mission.{' '}
					</p>
					<h6 className='display-6'>Invitation</h6>
					<p className='lead text-left'>
						Each service concludes with an invitation. To the new
						believer this is an opportunity to come forward and
						commit one’s life to Christ. To the person who is
						already a baptized believer we extend the opportunity to
						join us in Christian fellowship as a member of CCCC.
					</p>
					<h6 className='display-6'>Sunday School</h6>
					<p className='lead text-left'>
						Our Sunday School classes meet at 9:30am to encourage,
						edify, and educate us as Christians. Classes are
						available for all ages, with multiple class options
						available for adults.
					</p>
					<h6 className='display-6'>Praise and Worship</h6>
					<p className='lead text-left'>
						Praise and Worship through music is central to much of
						what we do here at CCCC, and we offer many ways for you
						to become involved. In addition to the regular worship
						time on Sunday mornings, our church choir leads us in
						worship during special Christmas and Easter programs. We
						also have other special music events throughout the
						year, and would love to have you join us for any of
						them!
					</p>

					<h6 className='display-6'>Library</h6>
					<p className='lead text-left'>
						The CCCC library is open before and after Sunday and
						Wednesday services and is available to anyone (note that
						if you are new to the church, you must first provide
						accurate contact information to have on file before
						checking anything out of the library). There are over
						4,000 items available in our library, ranging from
						fiction, theological commentaries, children’s books,
						dvds, music cds, Bible Studies, devotionals, and much,
						much more.{' '}
					</p>
					<h6 className='display-6'>Church Van</h6>
					<p className='lead text-left'>
						The Cave City Christian Church offers transportation for
						those in the immediate Cave City area who are unable to
						make it to church services on their own. The van runs
						for both Sunday morning services, Midweek Bible Studies,
						and for some special events. If you would like to join
						us and are in need of transportation to get to the
						church, just give us a call in advance and we will do
						our best to help you get here!
					</p>
				</div>
			</Container>
		</div>
	);
};

export default Ministries;
