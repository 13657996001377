import React from 'react';
import OurStorytron from '../../components/Jumbotron/OurStorytron';
import Students from './sections/Students';

const ForStudents = () => {
	return (
		<div>
			<OurStorytron title={'Students'} />
			<Students />
		</div>
	);
};

export default ForStudents;
