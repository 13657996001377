import React from 'react';
import OurStorytron from '../../components/Jumbotron/OurStorytron';
import Adults from './sections/Adults';

const ForAdults = () => {
	return (
		<div>
			<OurStorytron title={'Adults'} />
			<Adults />
		</div>
	);
};

export default ForAdults;
