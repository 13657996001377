import React from "react";
import Sanctuary from "../../images/sanctuary.png";

import "./Givetron.css";

const Contacttron = () => {
  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-md-12">
          <img
            src={Sanctuary}
            width="1980"
            height="400"
            alt="placeholder 960"
            className="img-responsive"
          />
          <div className="centered display-2">Contact Us</div>
        </div>
      </div>
    </div>
  );
};

export default Contacttron;
