import React from 'react';

import './ShowCase.css';

const ShowCase = (props) => {
	var url = props.src;
	return (
		<div className='embed-responsive embed-responsive-16by9 showcase-parent'>
			<iframe
				title='Sermon-Video'
				className='embed-responsive-item showcase-iframe'
				src={url}
				allowFullScreen></iframe>
		</div>
	);
};

export default ShowCase;
