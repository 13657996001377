import React from 'react';

const Events = () => {
	return (
		<h1 className='mt-5 mb-5'>
			Check back in the future for upcoming events at CCCC
		</h1>
	);
};

export default Events;
