import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import ShowCase from '../../../components/IFrame/ShowCase';
import { fetchData } from '../../../api/fetch';

import './SermonGrid.css';

class SermonGrid extends Component {
	constructor(props) {
		super(props);
		this.state = { live: [{}], grid: '', fetching: false };
		this.recurrLive = this.recurrLive.bind(this);
	}

	componentDidMount() {
		this.setState({ fetching: true });
		this.recurrLive();
	}

	recurrLive = async () => {
		const shows = await fetchData('ShowCase');
		this.setState({ live: shows, fetching: false });
	};

	render() {
		const iframes = this.state.live;
		let inner = [];
		for (let i = 0; i < iframes.length; i++) {
			inner.push(
				<Col className='pt-3'>
					<ShowCase
						key={`${iframes[i].link}/embed`}
						src={`${iframes[i].link}/embed`}
					/>
				</Col>
			);
		}
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',

					height: '100vh',
				}}>
				<Container fluid>
					{this.state.fetching ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}>
							<Spinner animation='border' role='status'>
								<span className='visually-hidden'>
									Loading...
								</span>
							</Spinner>
						</div>
					) : (
						<Row>{inner}</Row>
					)}
				</Container>
			</div>
		);
	}
}

export default SermonGrid;
