import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Container from 'react-bootstrap/Container';
import './Footer.css';

const Footer = () => {
	return (
		<div>
			<footer className='site-footer mt-5'>
				<Container>
					<div className='row'>
						<div className='col-sm-12 col-md-6'>
							<h6>John 3:16</h6>
							<p className='text-justify'>
								For this is how God loved the world: He gave his
								one and only Son, so that everyone who believes
								in him will not perish but have eternal life.
							</p>
						</div>

						<div className='col-xs-6 col-md-3'>
							<h6>Service Times</h6>
							<ul className='footer-links'>
								<li>
									<span>
										Sunday School: 9:30 a.m.
										<br />
										Sunday Service: 10:30 a.m.
										<br />
										Wednesday Bible Study: 6:30 p.m.
									</span>
								</li>
								<br />
								<li>
									<h6>Church Location</h6>
								</li>
								<span>
									300 Broadway St
									<br />
									P.O.Box 487
									<br />
									Cave City, KY 42127
								</span>
							</ul>
						</div>

						<div className='col-xs-6 col-md-3'>
							<h6>Quick Links</h6>
							<ul className='footer-links'>
								<li>
									<a href='/give'>Give</a>
								</li>
								<li>
									<a href='/pastor'>Pastor</a>
								</li>
								<li>
									<a href='/events'>Events</a>
								</li>
								<li>
									<a href='/sermons'>Sermons</a>
								</li>
								<li>
									<a href='/contactus'>Contact Us</a>
								</li>
							</ul>
						</div>
					</div>
					<hr />
				</Container>
				<Container>
					<div className='row'>
						<div className='col-md-8 col-sm-6 col-xs-12'>
							<p className='copyright-text'>
								Copyright &copy; {new Date().getFullYear()} All
								Rights Reserved by Cave City Christian Church.
							</p>
						</div>

						<div className='col-md-4 col-sm-6 col-xs-12'>
							<ul className='social-icons'>
								<li>
									<a
										className='facebook'
										href='https://www.facebook.com/pages/category/Religious-Organization/Cave-City-Christian-Church-145050992258268/'>
										<FontAwesomeIcon icon={faFacebookF} />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</Container>
			</footer>
		</div>
	);
};

export default Footer;
