import React from 'react';
import Sanctuary from '../../images/sanctuary.png';

import './Sermontron.css';

const Sermontron = () => {
	return (
		<div>
			<div className='container-fluid px-0'>
				<div className='row'>
					<div className='col-md-12'>
						<img
							src={Sanctuary}
							alt='placeholder 960'
							className='img-responsive sermontron-img'
						/>
						<div className='centered display-2'>Services</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sermontron;
