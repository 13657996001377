import React from "react";
import Container from "react-bootstrap/Container";

import TithelyLogo from "../../../images/Tithely-Logomark-Color-Dark.jpg";

const Tithely = () => {
  return (
    <div>
      <Container className="mx-auto">
        <hr />
        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 className="display-4">Give Online</h1>
          <a href="https://tithe.ly/give?c=3350476">
            <img
              src={TithelyLogo}
              alt="Tithely Logo"
              width="300"
              height="100"
            />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Tithely;
