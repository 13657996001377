import React from "react";
import Sermontron from "../../components/Jumbotron/Sermontron";
import SermonGrid from "./sections/SermonGrid";
const Sermon = () => {
  return (
    <div>
      <Sermontron />
      <SermonGrid />
    </div>
  );
};

export default Sermon;
