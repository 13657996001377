import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Col, Row } from 'react-bootstrap';

import './Ourchurch.css';

const Ourchurch = () => {
	return (
		<div>
			<Container className='mt-5'>
				<hr />
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>Our Church Ministries</h1>
				</div>
			</Container>
			<Container>
				<Row className='church-card-row'>
					<Col className='church-column'>
						<Card className='church-card'>
							{/* <Card.Img
								variant='top'
								src='https://picsum.photos/286/180'
							/> */}
							<Card.Body>
								<Card.Title className='church-card-title'>
									Adults
								</Card.Title>
								<Card.Text>
									We believe Church is so much more than just
									Sunday mornings. Take a look at all the
									opportunities where you can become involved!
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col className='church-column'>
						<Card className='church-card'>
							{/* <Card.Img
								variant='top'
								src='https://picsum.photos/286/180'
							/> */}
							<Card.Body>
								<Card.Title className='church-card-title'>
									Students
								</Card.Title>
								<Card.Text>
									We believe in the importance of raising the
									next generation of believers. Come join us
									for any of our youth programs!
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col className='church-column'>
						<Card className='church-card'>
							{/* <Card.Img
								variant='top'
								src='https://picsum.photos/286/180'
							/> */}
							<Card.Body>
								<Card.Title className='church-card-title'>
									Everyone
								</Card.Title>
								<Card.Text>
									We seek to be a Church Family, where anyone
									and everyone can feel at home. No matter
									where you are in life, you are invited to
									join us!
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Ourchurch;
