import React from 'react';
import OurStorytron from '../../components/Jumbotron/OurStorytron';
import Ministries from './sections/Ministries';

const OurMinistries = () => {
	return (
		<div>
			<OurStorytron title={'Ministries'} />
			<Ministries />
		</div>
	);
};

export default OurMinistries;
