import React from "react";
import Pastortron from "../../components/Jumbotron/Pastortron";
import LifeStory from "./sections/LifeStory";

const Pastor = () => {
  return (
    <div>
      <Pastortron />
      <LifeStory />
    </div>
  );
};

export default Pastor;
