import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import RecurringLive from '../../../components/IFrame/RecurringLive';
import { LinkContainer } from 'react-router-bootstrap';

const Sermon = () => {
	return (
		<div>
			<Container className='mt-5'>
				<hr />
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>Our Church Services</h1>
					<p className='lead'>
						Take a look at our latest Sunday service. You are
						welcome to join us in-person for services or online
						anytime!
					</p>
				</div>
			</Container>
			<Container style={{ display: 'flex', justifyContent: 'center' }}>
				<RecurringLive />
			</Container>
			<Container className='pt-3 d-flex justify-content-center'>
				<LinkContainer to='/sermons'>
					<Button variant='outline-secondary' className='button-b'>
						VIEW ALL SERVICES
					</Button>
				</LinkContainer>
			</Container>
		</div>
	);
};

export default Sermon;
