import React from 'react';
import Container from 'react-bootstrap/Container';

import './Story.css';

const Students = () => {
	return (
		<div>
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>For Students</h1>
					<br />
					<h6 className='display-6'>Sunday Service Youth Programs</h6>
					<p className='lead text-left'>
						During the regular 10:30am service, we offer nursery and
						children’s church alternatives for our younger
						attendees. Children present during services are welcome
						to stay in the sanctuary with the adults or attend
						age-appropriate classes after the communion portion of
						our services. Please contact us in advance if you have
						any questions or specific needs.
					</p>
					<h6 className='display-6'>Sunday School</h6>
					<p className='lead text-left'>
						Our Sunday School classes meet at 9:30 on Sunday
						mornings. We offer a variety of youth classes.
					</p>

					<h6 className='display-6'>Vacation Bible School</h6>
					<p className='lead text-left'>
						Our congregation hosts a Vacation Bible School each
						summer as an opportunity for students to come together
						to learn about Jesus while having a blast! Check our
						upcoming events page in the summertime for more
						information.{' '}
					</p>
					<h6 className='display-6'>Special Events</h6>
					<p className='lead text-left'>
						Throughout the year CCCC hosts a variety of special
						events, many of which are either geared for children or
						provide opportunities for our youngest members to be
						involved. Check out our events page for information
						about upcoming opportunities!
					</p>
				</div>
			</Container>
		</div>
	);
};

export default Students;
