import React from 'react';
import Container from 'react-bootstrap/Container';

const Mail = () => {
	return (
		<div>
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>Give By Mail</h1>
					<p className='lead'>
						Cave City Christian Church
						<br />
						P.O. Box 487
						<br />
						Cave City, KY 42127
					</p>
				</div>
			</Container>
		</div>
	);
};

export default Mail;
