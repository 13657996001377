import React from 'react';
import Hometron from '../../components/Jumbotron/Hometron';
import Welcome from './sections/Welcome';
import Ourchurch from './sections/Ourchurch';
import Sermon from './sections/Sermon';

const Home = () => {
	return (
		<div>
			<Hometron />
			<Welcome />
			<Ourchurch />
			<Sermon />
		</div>
	);
};

export default Home;
