import React from 'react';
import OurStorytron from '../../components/Jumbotron/OurStorytron';
import Story from './sections/Story';

// This is about us
const OurStory = () => {
	return (
		<div>
			<OurStorytron title={'About Us'} />
			<Story />
		</div>
	);
};

export default OurStory;
