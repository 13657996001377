import React from 'react';
import Container from 'react-bootstrap/Container';

import './Story.css';

const Story = () => {
	return (
		<div>
			<Container className='mt-5'>
				<div className='pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center'>
					<h1 className='display-4'>About Us</h1>
					<br />
					<p className='lead text-left'>
						We are a church who tries to put the spotlight on Jesus.
						We come from all backgrounds and denominations. Some of
						us grew up in church and have been disciples our entire
						lives. Some of us came to Christ when we are adults.
						Some of us are seekers who have yet to make a commitment
						to Him. We are convinced that Jesus loves all of us and
						it is our goal to help you grow in your relationship
						with Him.
					</p>
					<p className='lead text-left'>
						You can expect to be welcomed regardless of where you
						are in your faith and walk with Christ. We are a church
						of diverse economic, professional, and social statuses.
						We are made up of families, singles, single parents,
						widows and widowers.
					</p>
					<p className='lead text-left'>
						You can expect to be welcomed no matter what you look
						like or what you are wearing. On Sunday mornings you
						will see some people in a suit or a dress. You will also
						see some in jeans. You are welcome and will not be out
						of place no matter how you are dressed.
					</p>
					<p className='lead text-left'>
						We want to meet you and get to know you. We do not
						expect anything from you. We simply want you to learn
						more about Jesus and grow in your relationship with Him.
						We are committed to do whatever we can to help you
						achieve that goal.
					</p>
					<h2 className='display-5'>Our History</h2>

					<p className='lead text-left'>
						The Cave City Christian Church has a history of service
						to Christ and our community reaching back over more than
						150 years. The church originally began in 1867 and has
						withstood the test of time down to the present day. We
						are an independent, non-denominational, Christian Church
						inviting and encouraging fellowship with all who profess
						Jesus as Lord. We are associated with the historic
						Restoration Movement, centered on the Lordship of
						Christ, the Unity of All Believers, and the Authority of
						Scripture.
					</p>
				</div>
			</Container>
		</div>
	);
};

export default Story;
